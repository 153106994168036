export interface ConfigState {
    configValues: Config;
}

export interface Config {
    authority: string;
    scopes: string[];
    clientId: string;
    backendUri: string;
    portalName: string;
    reactAppAccentColor: string;
    reactAppAccentColorOnHover: string;
    reactAppAccentColorDarkmode: string;
    reactAppAccentColorOnHoverDarkmode: string;
}

export const initialConfigState: ConfigState = {
    configValues: {
        authority: process.env.REACT_APP_AAD_AUTHORITY as string,
        scopes: ['openid', 'offline_access', 'profile'].concat(
            (process.env.REACT_APP_AAD_API_SCOPE as string) ? [process.env.REACT_APP_AAD_API_SCOPE as string] : [],
        ),
        clientId: process.env.REACT_APP_AAD_CLIENT_ID as string,
        backendUri: process.env.REACT_APP_BACKEND_URI as string,
        portalName: process.env.REACT_APP_PORTAL_NAME as string,
        reactAppAccentColor: process.env.REACT_APP_ACCENT_COLOR as string,
        reactAppAccentColorOnHover: process.env.REACT_APP_ACCENT_COLOR_ON_HOVER as string,
        reactAppAccentColorDarkmode: (process.env.REACT_APP_ACCENT_COLOR_DARKMODE as string)
            ? (process.env.REACT_APP_ACCENT_COLOR_DARKMODE as string)
            : (process.env.REACT_APP_ACCENT_COLOR as string),
        reactAppAccentColorOnHoverDarkmode: (process.env.REACT_APP_ACCENT_COLOR_ON_HOVER_DARKMODE as string)
            ? (process.env.REACT_APP_ACCENT_COLOR_ON_HOVER_DARKMODE as string)
            : (process.env.REACT_APP_ACCENT_COLOR_ON_HOVER as string),
    },
};

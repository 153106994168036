import { createContext } from 'react';

import { TeamsUserCredential } from '@microsoft/teamsfx';

export const TeamsFxContext = createContext<{
    credential?: TeamsUserCredential;
    tenantId: string;
    objectId: string;
    userEmail: string;
    setTenantId: (tenantId: string) => void;
    setObjectId: (objectId: string) => void;
    setUserEmail: (userEmail: string) => void;
}>({
    credential: undefined,
    tenantId: '',
    objectId: '',
    userEmail: '',
    setTenantId: () => {},
    setObjectId: () => {},
    setUserEmail: () => {},
});

import i18next from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import translationGerman from './locales/de.json';
import translationEnglish from './locales/en.json';

const resources = {
    en: {
        translation: translationEnglish,
    },
    de: {
        translation: translationGerman,
    },
};

i18next
    .use(detector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
    })
    .catch((err) => {
        console.error(`i18next init error: ${err}`);
    });

export default i18next;

import * as msTeams from '@microsoft/teams-js';
import { useTeams, useTeamsUserCredential } from '@microsoft/teamsfx-react';
import { useEffect } from 'react';
import { store } from './redux/app/store';
import { setTeamsCredentials } from './redux/teams/teamsSlice';

const TeamsProvider = ({ children }: { children: React.ReactNode }) => {
    // TEAMS
    const teams = useTeams();
    const inTeams = teams[0].inTeams;
    const { teamsUserCredential } = useTeamsUserCredential({
        initiateLoginEndpoint: process.env.REACT_APP_START_LOGIN_PAGE_URL + '/auth-start.html',
        clientId: store.getState().config.configValues.clientId,
    });

    useEffect(() => {
        async function initialize() {
            await msTeams.app.initialize().then(() => {
                const handleThemeChange = (theme: string) => {
                    if (theme === 'dark') {
                        console.log('dark mode');
                    }
                    if (theme === 'default' || theme === 'contrast') {
                        console.log('light mode');
                    }
                };

                msTeams.app.registerOnThemeChangeHandler(handleThemeChange);
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                msTeams.app.getContext().then((_context) => {
                    if (_context.user) {
                    } else {
                        msTeams.authentication.authenticate({
                            url: store.getState().config.configValues.backendUri, // Backend Azure Function endpoint for authentication
                            successCallback: (result: any) => {
                                // Authentication successful
                                console.log('Authentication successful', result);
                            },
                            failureCallback: (reason: any) => {
                                // Authentication failed
                                console.error('Authentication failed:', reason);
                            },
                        });
                    }
                });
            });
        }

        function fetchTeams() {
            if (teamsUserCredential) {
                try {
                    store.dispatch(setTeamsCredentials({ teamsUserCredential, inTeams }));
                } catch (error) {
                    console.log('ERROR TRY-CATCH', error);
                }
            }
        }
        void initialize();

        fetchTeams();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inTeams, teamsUserCredential]);

    useEffect(() => {
        store.dispatch(setTeamsCredentials({ teamsUserCredential, inTeams }));
    }, [inTeams, teamsUserCredential]);

    return <div>{children}</div>;
};

export default TeamsProvider;

import { Switch, makeStyles, tokens } from '@fluentui/react-components';
import React, { useCallback } from 'react';
import { AbtisConfig } from '../../abtis-config';
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { FeatureKeys } from '../../redux/features/app/AppState';
import { toggleFeatureFlag } from '../../redux/features/app/appSlice';
import { abtisAccentColorValueString, abtisAccentHoverColorValueString } from '../../styles';

export const useClasses = makeStyles({
    root: {
        color: tokens.colorNeutralForeground1,
    },
    customColor: {
        '& div': {
            backgroundColor: abtisAccentColorValueString,
        },
        '& input': {
            '&:hover + div': {
                backgroundColor: abtisAccentHoverColorValueString,
            },
        },
    },
});

export const DarkModeSwitch: React.FC = () => {
    const { features } = useAppSelector((state: RootState) => state.app);
    const dispatch = useAppDispatch();
    const key = FeatureKeys.DarkMode;
    const feature = features[key];
    const darkmodeEnabled = feature.enabled;

    const classes = useClasses();

    const onFeatureChange = useCallback(
        (featureKey: FeatureKeys, value: boolean) => {
            localStorage.setItem(AbtisConfig.localStorageKeys.abtisChatbotDarkmodeEnabledName, JSON.stringify(value));
            dispatch(toggleFeatureFlag(featureKey));
        },
        [dispatch],
    );

    return (
        <Switch
            className={darkmodeEnabled ? classes.customColor : ''}
            label={feature.label}
            checked={feature.enabled}
            disabled={feature.inactive}
            onChange={() => {
                onFeatureChange(key, !feature.enabled);
            }}
        />
    );
};

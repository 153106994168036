import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import ReactDOM from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import App from './App';
import { Constants } from './Constants';
import './index.css';
import { AuthConfig, AuthHelper, AuthType } from './libs/auth/AuthHelper';
import { store } from './redux/app/store';

import React from 'react';

import { FluentProvider } from '@fluentui/react-components';
import TeamsProvider from './TeamsProvider';
import './i18next';
import { ConfigData } from './libs/models/ConfigData';
import { setAuthConfig } from './redux/features/app/appSlice';
import { setConfig } from './redux/features/config/configSlice';
import { semanticKernelLightTheme } from './styles';

if (!localStorage.getItem('debug')) {
    localStorage.setItem('debug', `${Constants.debug.root}:*`);
}

const inTeams = window.parent !== window.self;

async function getConfigData() {
    if (process.env.REACT_APP_HOST?.toLowerCase() === 'shell' || process.env.NODE_ENV?.toLowerCase() === 'production') {
        await fetch('/api/config')
            .then((response) => response.json())
            .then((data: ConfigData) => {
                store.dispatch(
                    setConfig({
                        authority: data.REACT_APP_AAD_AUTHORITY,
                        scopes: data.REACT_APP_AAD_API_SCOPE.split(' '),
                        clientId: data.REACT_APP_AAD_CLIENT_ID,
                        backendUri: data.REACT_APP_BACKEND_URI,
                        portalName: data.REACT_APP_PORTAL_NAME,
                        reactAppAccentColor: data.REACT_APP_ACCENT_COLOR,
                        reactAppAccentColorOnHover: data.REACT_APP_ACCENT_COLOR_ON_HOVER,
                        reactAppAccentColorDarkmode:
                            data.REACT_APP_ACCENT_COLOR_DARKMODE ?? data.REACT_APP_ACCENT_COLOR,
                        reactAppAccentColorOnHoverDarkmode:
                            data.REACT_APP_ACCENT_COLOR_ON_HOVER_DARKMODE ?? data.REACT_APP_ACCENT_COLOR_ON_HOVER,
                    }),
                );
            })
            .catch((error) => {
                console.log(error);
            });
    }
}

let root: ReactDOM.Root | undefined = undefined;
let msalInstance: PublicClientApplication | undefined;

document.addEventListener('DOMContentLoaded', () => {
    const container = document.getElementById('root');
    if (!container) {
        throw new Error('Could not find root element');
    }

    root = ReactDOM.createRoot(container);

    if (!inTeams) {
        renderApp();
    } else {
        renderTeamsApp();
    }
});

export function renderApp() {
    getConfigData()
        .then(() => {
            const authConfig: AuthConfig = {
                authType: AuthType.AAD,
                aadClientId: store.getState().config.configValues.clientId,
                aadAuthority: store.getState().config.configValues.authority,
                aadApiScope: store.getState().config.configValues.scopes.join(' '),
            };

            store.dispatch(setAuthConfig(authConfig));

            if (AuthHelper.isAuthAAD() && !inTeams) {
                if (!msalInstance) {
                    msalInstance = new PublicClientApplication(AuthHelper.getMsalConfig(authConfig));
                    void msalInstance.handleRedirectPromise().then((response) => {
                        if (response) {
                            msalInstance?.setActiveAccount(response.account);
                        }
                    });
                }

                root?.render(
                    <React.StrictMode>
                        <ReduxProvider store={store}>
                            {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
                            <MsalProvider instance={msalInstance}>
                                <FluentProvider className="app-container" theme={semanticKernelLightTheme}>
                                    <App inTeams={false} />
                                </FluentProvider>
                            </MsalProvider>
                        </ReduxProvider>
                    </React.StrictMode>,
                );
            }
        })
        .catch(() => {
            store.dispatch(setAuthConfig(undefined));
        });
}

export function renderTeamsApp() {
    getConfigData()
        .then(() => {
            const authConfig: AuthConfig = {
                authType: AuthType.AAD,
                aadClientId: store.getState().config.configValues.clientId,
                aadAuthority: store.getState().config.configValues.authority,
                aadApiScope: store.getState().config.configValues.scopes.join(' '),
            };

            store.dispatch(setAuthConfig(authConfig));

            root?.render(
                <React.StrictMode>
                    <ReduxProvider store={store}>
                        <TeamsProvider>
                            {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
                            <App inTeams={true} />
                        </TeamsProvider>
                    </ReduxProvider>
                </React.StrictMode>,
            );
        })
        .catch(() => {
            store.dispatch(setAuthConfig(undefined));
        });
}

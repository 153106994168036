import {
    Add20Filled,
    Add20Regular,
    AppsAddIn24Filled,
    AppsAddIn24Regular,
    ArrowDownload16Filled,
    ArrowDownload16Regular,
    BotAdd20Filled,
    BotAdd20Regular,
    Checkmark20Filled,
    Checkmark20Regular,
    Delete16Filled,
    Delete16Regular,
    DeleteFilled,
    DeleteRegular,
    Dismiss16Filled,
    Dismiss16Regular,
    Dismiss20Filled,
    Dismiss20Regular,
    Dismiss24Filled,
    Dismiss24Regular,
    EditFilled,
    EditRegular,
    Filter20Filled,
    Filter20Regular,
    Info16Filled,
    Info16Regular,
    Info24Filled,
    Info24Regular,
    Share20Filled,
    Share20Regular,
    ThumbDislike16Filled,
    ThumbDislike16Regular,
    ThumbLike16Filled,
    ThumbLike16Regular,
    WeatherMoon24Filled,
    WeatherMoon24Regular,
    WeatherSunny24Filled,
    WeatherSunny24Regular,
    bundleIcon,
} from '@fluentui/react-icons';

export const Add20 = bundleIcon(Add20Filled, Add20Regular);
export const AppsAddIn24 = bundleIcon(AppsAddIn24Filled, AppsAddIn24Regular);
export const ArrowDownload16 = bundleIcon(ArrowDownload16Filled, ArrowDownload16Regular);
export const BotAdd20 = bundleIcon(BotAdd20Filled, BotAdd20Regular);
export const Checkmark20 = bundleIcon(Checkmark20Filled, Checkmark20Regular);
export const Delete16 = bundleIcon(Delete16Filled, Delete16Regular);
export const Delete = bundleIcon(DeleteFilled, DeleteRegular);
export const Dismiss16 = bundleIcon(Dismiss16Filled, Dismiss16Regular);
export const Dismiss20 = bundleIcon(Dismiss20Filled, Dismiss20Regular);
export const Dismiss24 = bundleIcon(Dismiss24Filled, Dismiss24Regular);
export const Edit = bundleIcon(EditFilled, EditRegular);
export const Filter20 = bundleIcon(Filter20Filled, Filter20Regular);
export const Info16 = bundleIcon(Info16Filled, Info16Regular);
export const Info24 = bundleIcon(Info24Filled, Info24Regular);
export const WeatherSunny24 = bundleIcon(WeatherSunny24Filled, WeatherSunny24Regular);
export const WeatherMoon24 = bundleIcon(WeatherMoon24Filled, WeatherMoon24Regular);
export const Share20 = bundleIcon(Share20Filled, Share20Regular);
export const ThumbLike16 = bundleIcon(ThumbLike16Filled, ThumbLike16Regular);
export const ThumbDislike16 = bundleIcon(ThumbDislike16Filled, ThumbDislike16Regular);

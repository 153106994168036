import { Link, makeStyles, tokens } from '@fluentui/react-components';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Breakpoints } from '../../styles';

export const useClasses = makeStyles({
    root: {
        color: 'tokens.colorNeutralForeground1',
        ...Breakpoints.small({
            marginLeft: tokens.spacingHorizontalXXL,
        }),
    },
    active: {
        color: tokens.colorNeutralForeground1Hover,
        textDecorationLine: 'underline',
    },
});

export const LanguageSwitch: React.FC = () => {
    const classes = useClasses();
    const { i18n } = useTranslation();
    const [currentLanguage, setCurrentLanguage] = React.useState<string>('de');

    const onLanguageChange = (language: string) => {
        void i18n.changeLanguage(language);
    };

    useEffect(() => {
        setCurrentLanguage(i18n.language);
    }, [i18n.language]);

    return (
        <div className={classes.root}>
            <Link
                className={currentLanguage.includes('de') ? classes.active : ''}
                appearance="subtle"
                onClick={() => {
                    onLanguageChange('de');
                }}
            >
                DE
            </Link>
            <span> | </span>
            <Link
                className={currentLanguage.includes('en') ? classes.active : ''}
                appearance="subtle"
                onClick={() => {
                    onLanguageChange('en');
                }}
            >
                EN
            </Link>
        </div>
    );
};

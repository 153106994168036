import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Config, ConfigState, initialConfigState as initialState } from './ConfigState';

//define a new slice with an initial state that contains the authority, scopes, clientId, backendUri, and portalName properties.
export const configSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {
        setConfig(state: ConfigState, action: PayloadAction<Config>) {
            state.configValues = action.payload;
        },
    },
});

export const { setConfig } = configSlice.actions;

export default configSlice.reducer;

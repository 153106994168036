import { TeamsUserCredential } from '@microsoft/teamsfx';

export interface TeamsState {
    teamsValues: TeamsValues;
}

export interface TeamsValues {
    teamsUserCredential?: TeamsUserCredential;
    inTeams?: boolean;
}

export const initialTeamsState: TeamsState = {
    teamsValues: {
        teamsUserCredential: undefined,
        inTeams: false,
    },
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialTeamsState as initialState, TeamsState, TeamsValues } from './TeamsState';

export const teamsCredentialsSlice = createSlice({
    name: 'teams',
    initialState,
    reducers: {
        setTeamsCredentials(state: TeamsState, action: PayloadAction<TeamsValues>) {
            state.teamsValues = action.payload;
        },
    },
});

export const { setTeamsCredentials } = teamsCredentialsSlice.actions;

export default teamsCredentialsSlice.reducer;
